import { axiosInstance } from '../utils';
import { shareConstants, userConstants } from '../constants';

const userOption = (user = {}) => {
  const firstName = user.first_name || user.firstName;
  const lastName = user.last_name || user.lastName;
  const fullName = user.full_name?.trim();

  const userObj = {
    id: user.id,
    value: user.id,
    image: user.image,
    role: user.role,
    label: fullName,
    userName: fullName,
    firstName,
    lastName,
    fullName,
  };

  return { ...user, ...userObj };
};

const getUserOptions = (callback, params = {}) => {
  params.page ||= shareConstants.DEFAULT_PAGE;
  params.per_page ||= shareConstants.DEFAULT_PER_PAGE;
  params.managers ||= true;
  params.old = '1';

  axiosInstance
    .get(userConstants.BASE_PATH, { params })
    .then(res => callback(res.data.users.map(userOption)))
    .catch(() => callback([]));
};

export const userActions = {
  getUserOptions,
};
